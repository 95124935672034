import React from "react";
import { Movement } from "@components/Structural/MyFinance/Movements/Movement/Movement";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { isBrowser } from "@utils";
import LayoutMyAccounts from "@components/MyAccounts/Layout/Layout";
import { useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components/Structural";
import { useAuth } from "@hooks";

const editMovement = ({ location }) => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery("(max-width: 1240px)");

  if (isBrowser() && !location?.state) {
    navigate(Route.financialPortal);
  }

  const movement = isBrowser() ? location?.state.movement : "";

  return (
    <>
      <SEO
        title="Rokin - Mis hábitos - Editar movimiento"
        description="Edita movimientos creados con anterioridad"
      />
      {isMobile ? (
        <LayoutMyAccounts
          title={"EDITAR MOVIMIENTO"}
          onBackClick={() => navigate(Route.financialPortal + "?#habits")}
        >
          <Movement movement={movement} />
        </LayoutMyAccounts>
      ) : (
        <LayoutOnBoarding activeFinance>
          <LayoutMyAccounts
            title={"EDITAR MOVIMIENTO"}
            onBackClick={() => navigate(Route.financialPortal + "?#habits")}
            isDesktop
          >
            <Movement movement={movement} />
          </LayoutMyAccounts>
        </LayoutOnBoarding>
      )}
    </>
  );
};

export default editMovement;
