import React, { useCallback, useState } from "react";
import { Checkbox, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./Movement.style";
import { useAuth } from "@hooks";
import { BirthdateInput, TextInput } from "@components/Inputs";
import {
  ButtonOutlined,
  ButtonPrimary,
  ButtonText,
  NumericInput,
} from "@components/Reusables";
import {
  EDUCATION_WORK,
  ENTRETENIMIENT,
  FEEDING,
  FINANCE,
  GIFTS,
  HEALTH,
  LIVING_PLACE,
  OTHERS,
  RESTAURANTS,
  SHOPPING,
  SPORTS_AND_HOBBIES,
  TRANSPORTATION,
  TRIPS,
  TRANSFERS,
} from "images/GCP/habits";
import { FinerioTransaction } from "@interfaces";
import { editFinerioTransaction } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { getFinerioSubcategories } from "@apollo";
import _ from "lodash";
import { DatePicker } from "@material-ui/pickers";

// TODO: Fix this to use the subcategories grouped by category from database and create a getSubcategoryIcon

interface LayoutProps {
  textButton?: string;
  className?: string;
  movement?: FinerioTransaction;
}

export const Movement: React.FC<LayoutProps> = ({
  className,
  textButton,
  movement,
}) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { isLoggedIn } = useAuth();
  const classes = useStyles({ isLoggedIn });
  const client = useApolloClient();
  const { finerioSubcategories } = getFinerioSubcategories();
  const [categorySelect, setCategorySelect] = useState<string>("all");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<string>();
  const [newDescription, setNewDescription] = useState<string>(
    movement?.description ?? ""
  );
  const [newAmount, setNewAmount] = useState<number>(
    Number(movement?.amount) ?? 0
  );
  const [newDate, setNewDate] = useState<Date>(
    new Date(movement?.date) ?? new Date()
  );

  const onSave = () => {
    editFinerioTransaction(client, {
      transactionId: String(movement?.id) ?? "",
      description: newDescription,
      amount: newAmount,
      date: newDate.toISOString().split("T")[0],
      subcategory:
        selectedSubcategoryId ?? movement?.subcategoryId.subcategoryId,
    });
    navigate(Route.finance + "?#habits");
  };

  const subcategoriesGrouped = _.groupBy(finerioSubcategories, function (item) {
    return item.FinerioCategory.name;
  });

  return (
    <div className={`${classes.container} ${className || ""} `}>
      <section className={classes.nameSpent}>
        {" "}
        <span>
          * Descripcion del {movement?.isCharge ? "gasto" : "ingreso"}
        </span>
        <div>
          <TextInput
            className={classes.textFieldButton}
            label="Nombre"
            onChange={(e) => setNewDescription(e.target.value)}
            value={newDescription}
          />
          <NumericInput
            className={classes.textFieldButton}
            label="Monto"
            onChange={(e) => setNewAmount(e.target.value)}
            name={""}
            value={newAmount}
          />
          <DatePicker
            className={classes.textFieldButton}
            name="date"
            label="Fecha"
            value={newDate}
            onChange={(e) => setNewDate(e)}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            openTo="date"
            cancelLabel="Cancelar"
          />
        </div>
        <br></br>
        <div>
          <TextInput
            className={classes.textFieldButton}
            label="Categoría"
            onChange={() => {}}
            value={
              movement?.newSubcategory
                ? movement?.newSubcategory?.FinerioCategory.name
                : movement?.subcategoryId?.FinerioCategory.name
            }
            disabled
          />
          <TextInput
            className={classes.textFieldButton}
            label="Subcategoría"
            onChange={() => {}}
            value={
              movement?.newSubcategory
                ? movement?.newSubcategory?.description
                : movement?.subcategoryId?.description
            }
            disabled
          />
        </div>
      </section>
      <section className={classes.category}>
        <div>
          <span className="uppercase">CATEGORÍA</span>
          {categorySelect === "all" ? (
            <div className={classes.categories}>
              {Object.entries(subcategoriesGrouped).map((category) => {
                return (
                  <div
                    onClick={() => {
                      setCategorySelect(category[1][0].FinerioCategory.name);
                    }}
                    className={classes.item}
                    key={category[1][0].FinerioCategory.id}
                  >
                    <img src={category[1][0].FinerioCategory.icon} />
                    <span>{category[1][0].FinerioCategory.name}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <section className={` ${classes.flex}`}>
              <div className={classes.categoryOnSubcat}>
                <img
                  src={
                    subcategoriesGrouped[categorySelect][0].FinerioCategory.icon
                  }
                />
                <span>
                  {subcategoriesGrouped[categorySelect][0].FinerioCategory.name}
                </span>
                <ButtonText
                  className={classes.button}
                  text="Ver más categorías"
                  onClick={() => {
                    setCategorySelect("all");
                  }}
                />
              </div>
              <div>
                <span className="uppercase">subcategoría</span>
                <div className={classes.subcategoriesDiv}>
                  {subcategoriesGrouped[categorySelect].map((category) => (
                    <div
                      onClick={() => {
                        setSelectedSubcategoryId(category.subcategoryId);
                      }}
                      key={category.subcategoryId}
                      className={classes.itemCategory}
                    >
                      {category.subcategoryId === selectedSubcategoryId ? (
                        <img src="https://rokinapp-images.s3.amazonaws.com/myHabits/subcategory/livingPalace/Action+_+check+circle+(1).svg" />
                      ) : (
                        <img src={category.icon} />
                      )}
                      <div>{category.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
        </div>
      </section>
      <div className={classes.buttons}>
        <h2>
          <ButtonPrimary
            className="first"
            text={textButton || "Guardar cambios"}
            noDegraded
            onClick={() => {
              onSave();
            }}
          />
        </h2>
        <ButtonOutlined
          className="secondary"
          text="Cancelar"
          onClick={() => {
            navigate(Route.finance + "?#habits");
          }}
        />
      </div>
    </div>
  );
};
